import styled from "styled-components";


export const StyledNavigationIcon = styled.div`
    position: relative;
    z-index: 10;

    ul {
        display: flex;

        li {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;

            &:nth-of-type(1) {
                margin-right: 20px;
            }

            svg {
                color: black;
                z-index: 2;
                height: 30px;
                width: 30px;

                g, line, rect  {
                    transition: 0.7s all ease;
                }

            }

      
          

            &:hover {
                #Arrow_hover{
                    opacity: 1;
                }
                
                #Arrow{
                    opacity: 0;
                }

                #Box {
                    width: 35px;
                }

                #Line_12481 {
                    Y2: 4.5px;
                    y2: 4.5px;
                }

                #Line_12482 {
                    y1: 4.5px;
                    Y1: 4.5px;
                }
            }
        }
    }
`;
