"use client";
import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { text, title } from "@/styles/globalStyleVars";

const TitleSerial = ({ text, line }) => {
  return (
    <StyledTitle line={line} className={`title-number`}>
      <h3 className={""}>{ReactHtmlParser(text + ".")} </h3>
    </StyledTitle>
  );
};

const StyledTitle = styled.div`
    margin: ${(props) => props.margin || "0px"};
    position: relative;
        // width: ${(props) => props.width || "fit-content"};
    font-family: ${title};
    text-align: ${(props) => (props?.center ? "center" : "")};
    padding: ${(p) => p.padding};
    z-index: 6;
    width: ${props => props.line ? "auto" : '100%'} ;
    //padding-left: 18px;
    overflow: hidden;
    padding-right: 10px;

    h3 {
        font-size: 35px;
        line-height: 40px;
        font-family: ${title};
        font-weight: 500;
        position: relative;

        &:after {
            position: absolute;
            content: "";
            display: ${props => props.line ? "none" : 'block'};
            height: 2px;
            width: calc(100% - 70px);
            background-color: ${text};
            bottom: 0;
            top: 0;
            margin: auto;
            right: -70px;
        }
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export default TitleSerial;
