"use client";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "@/components/Button";
import { Modal } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import Form from "@/components/common/Form";
import { CloseIcon } from "next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon";
import "simplebar-react/dist/simplebar.min.css";

const GuidanceComponent = ({ className, data, modalClass }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    setShow(true);
  };

  return (
    <StyledGuidanceComponent
      className={`${className ? className : null} guidance_components fade-up`}
    >
      <div className="guidance_component_wrapper">
        {data?.box_subtitle && (
          <h5 className={"split-up"}>{data?.box_subtitle}</h5>
        )}
        {data?.box_title && <h4 className={"split-up"}>{data?.box_title}</h4>}
        {data?.box_desc && <p className={"split-up"}>{data?.box_desc}</p>}
        <div onClick={handleShow}>
          <Button text={"Contact Us"} margin={"40px 0 0"} />
        </div>
      </div>

      <Modal
        className={`team-modal team-modal-form ${
          modalClass ? modalClass : null
        }`}
        show={show}
        onHide={handleClose}
      >
        <div className="team-modal__close" onClick={handleClose}>
          <CloseIcon />
        </div>
        <SimpleBar autoHide={true} style={{ maxHeight: "85vh" }}>
          <div className="team-modal__content">
            <h3 className="top-text">Contact</h3>
            <h3 className="bottom-text">us</h3>
            <Form />
          </div>
        </SimpleBar>
      </Modal>
    </StyledGuidanceComponent>
  );
};

const StyledGuidanceComponent = styled.div`
  width: 100%;

  .guidance_component_wrapper {
    background: #ffffff;
    width: 100%;
    padding: 40px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    h5 {
      font-family: "Aeonik";
      color: #191919;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    h4 {
      font-family: "Aeonik";
      color: #e62e43;
      bottom: 40px;
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 40px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: rgba(25, 25, 25, 0.5);
    }
  }
`;

export default GuidanceComponent;
