"use client";
import React from "react";
import styled from "styled-components";
import {Col} from "react-bootstrap";
import {Img} from "@/components/Img";
import Title from "@/components/Title";
import TitleSerial from "@/components/TitleSerial";
import Button from "@/components/Button";
import {hover} from "@/styles/globalStyleVars";
import ThemeRow from "@/components/theme/row";
import ThemeContainer from "@/components/theme/container";
import reactHtmlParser from "react-html-parser";

const StockBrokerage = ({ data }) => {
  return (
    <StyledComponent className="stock pt-200 pb-200">
      {data?.bg_img && <Img src={data?.bg_img} alt={data?.section_name} />}
      {/*<ThemeRow>*/}
      {/*    /!*<Col sm={{ span: 5, offset: 7 }}>*!/*/}
      {/*    <Col sm={{ span: 5 }}>*/}
      {/*        <TitleSerial margin={"50px 0 0 0 "} text={"I"} />*/}
      {/*    </Col>*/}
      {/*</ThemeRow>*/}
      {data?.section_name && (
        <ThemeContainer colProps={{ span: 12 }}>
          <div className={'d-flex align-items-center justify-content-around'}>
            <TitleSerial line margin={"50px 0 0 0 "} text={"I"}/>
            <Title number={"I"} text={data?.section_name} />
          </div>
        </ThemeContainer>
      )}


      <ThemeRow rowClass={"stock__content"}>
        <Col sm={{ span: 5, offset: 1 }}>
          {data?.subtitle && (
            <h4 className={"fade-up"}>{reactHtmlParser(data?.subtitle)}</h4>
          )}

          {data?.desc && (
            <p className={"fade-up"}>{reactHtmlParser(data?.desc)}</p>
          )}
          <Button
            margin={"40px 0 0 0"}
            text={"Learn More"}
            src={data?.button_url}
          />
        </Col>

        <Col sm={{ span: 4, offset: 2 }}>
          <div data-speed={"1.1"}>
            {data?.title && reactHtmlParser(data?.title)}
          </div>
        </Col>
      </ThemeRow>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    object-fit: cover;
  }
  h4 {
    margin-top: 90px;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;

    p {
    }
  }
  h5 {
    font-size: 80px;
    line-height: 70px;
    color: ${hover};
    margin-top: 110px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  h6 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }
  @media (max-width: 992px) {
    .col-sm-4,
    .col-sm-5 {
      min-width: 100%;
      margin: 0;
    }

    .stock__content {
      flex-direction: column-reverse;
      margin-top: 20px;
    }

    h4 {
      margin-top: 70px;
    }
  }
`;
export default React.memo(StockBrokerage);
