"use client";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import useDeviceType from "@/components/hooks/useDeviceType";
import { Img } from "@/components/Img";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { hover } from "@/styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const SlideOne = ({
  topTitle,
  title,
  description,
  bannerLarge,
  bannerSmall,
  buttonLabel,
  buttonLink,
  buttonTarget,
  video,
}) => {
  const { isMobile } = useDeviceType();
  useEffect(() => {
    const navsLeft = document.querySelectorAll(".nav-left");
    const navsRight = document.querySelectorAll(".nav-right");

    navsLeft.forEach((e) => {
      e.addEventListener("click", () => {
        document.querySelector(".swiper-button-prev").click();
      });
    });

    navsRight.forEach((e) => {
      e.addEventListener("click", () => {
        document.querySelector(".swiper-button-next").click();
      });
    });
  }, [buttonLink]);

  return (
    <>
      <div className="slide-inner">
        {isMobile ? (
          <div className="mobile">
            {video ? (
              <video src={bannerSmall} autoPlay muted loop playsInline />
            ) : (
              <Img alt={title} src={bannerSmall} />
            )}
          </div>
        ) : (
          <div className="desktop">
            {video ? (
              <video src={bannerLarge} autoPlay muted loop playsInline />
            ) : (
              <Img alt={title} src={bannerLarge} />
            )}
          </div>
        )}

        <Container>
          <div className="nav-left"></div>
          <div className="nav-right"></div>
          <Row>
            <Col>
              <div className="slide-inner__info">
                <div className="top-title-wrapper">
                  <p className="toptitle">{topTitle}</p>
                </div>
                <div className="side-wrapper">
                  <div className="title-wrapper-middle">
                    <Title
                      classname={"swiper__title"}
                      text={title}
                      lineHeight={80}
                      fontSize={72}
                      fontWeight={"300"}
                      fontSizeResponsive={40}
                      lineHeightResponsive={48}
                      color={"white"}
                      margin={"0 "}
                    />
                  </div>
                  <div className="middle-description">
                    <p>{reactHtmlParser(description)}</p>
                  </div>
                  {buttonLink && (
                    <Button
                      color={"white"}
                      lineColor={hover}
                      iconBackground={"white"}
                      src={buttonLink}
                      margin={"55px 0 0"}
                      text={buttonLabel}
                      hoverBackground={hover}
                      hoverColor={hover}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

SlideOne.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  thumb: PropTypes.string,
};

export { SlideOne };
