"use client";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import Title from "@/components/Title";
import ThemeContainer from "@/components/theme/container";
import ThemeRow from "@/components/theme/row";
import Slider, { Slide } from "@/components/ui/swiper";
import React, { useRef } from "react";
import NavigationIcons from "@/components/ui/navigation-icons";
import reactHtmlParser from "react-html-parser";
import { EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";

const Testimonial = ({ data }) => {
  const SwiperRef = useRef();

  // Function to initialize swiper
  const handleSwiperInit = (swiper) => {
    return swiper;
  };

  return (
    <StyledComponent className="testimonials pb-200">
      <ThemeContainer colProps={{ md: 7 }}>
        {data?.section_name && (
          <div data-speed={"clamp(1.1)"}>
            <Title
              text={data?.section_name}
              version
              number={"IV"}
              background={"#8C263D"}
            />
          </div>
        )}
      </ThemeContainer>

      <ThemeRow>
        <Col sm={{ span: 3, offset: 1 }} className={"testimonials__navs"}>
          {data?.title && (
            <h4 className={"fade-up"}>{reactHtmlParser(data?.title)}</h4>
          )}
          {data?.desc && (
            <p className={"fade-up"}>{reactHtmlParser(data?.desc)}</p>
          )}

          <div className={"slider-nav"}>
            <NavigationIcons
              navigationPrev={"testimonial_prev"}
              navigationNext={"testimonial_next"}
            />
          </div>
        </Col>

        <Col sm={{ span: 7, offset: 1 }}>
          {data && data?.list?.length > 0 && (
            <Slider
              ref={SwiperRef}
              modules={[EffectFade]}
              navigationLeft={"#testimonial_next"}
              navigationRight={"#testimonial_prev"}
              speed={300}
              slidesPerView={1}
              slidesPerGroup={1}
              effect={"fade"}
              fadeEffect={{
                crossFade: true,
              }}
              loop={false}
              autoplay={false}
              spaceBetween={15}
              grabCursor={false}
              watchSlidesProgress={false}
              mousewheelControl={false}
              keyboardControl={false}
              onSwiperInit={handleSwiperInit}
              pagination={false}
            >
              {data &&
                data?.list?.length > 0 &&
                data?.list?.map((e, index) => {
                  return (
                    <Slide key={index}>
                      <div className="testimonials__slides">
                        <div className="testimonials__slides__single">
                          <img
                            className={"quotes-img"}
                            src="/images/static/quote.svg"
                            alt="Qoutes"
                          />
                          {e?.review && (
                            <div className="testimonials__slides__single__desc">
                              <p>{reactHtmlParser(e?.review)}</p>
                            </div>
                          )}

                          <div className="testimonials__slides__single__auth">
                            {e?.image && <img src={e?.image} alt={e?.image} />}
                            <div className="groups">
                              {e?.name && (
                                <h4 className={"split-up"}>
                                  {reactHtmlParser(e?.name)}
                                </h4>
                              )}
                              {e?.designation && (
                                <p className={"split-up"}>
                                  {reactHtmlParser(e?.designation)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slide>
                  );
                })}
            </Slider>
          )}
        </Col>
      </ThemeRow>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  background-color: #f4f4f4;
  padding-top: 80px;
    .title {
        p{
            margin-right: 10px;
        }
        h2 {
            padding: 0 !important;

            &:after , &:before{
                display: none !important;
            }
        }
    }
  .testimonials__navs {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 0;

    h4 {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 40px;
    }

    .slider-nav {
      margin-top: 40px;
    }
  }

  .testimonials__slides {
    width: 99%;
    &__single {
      border: 1px solid #191919;
      padding: 70px;
      position: relative;

      &__desc {
        display: flex;
        overflow: hidden;
        p {
          font-size: 20px;
          line-height: 28px;
          font-weight: 500;
          transform: translateY(50px);
          opacity: 0;
          transition: all 0.5s ease;
          transition-delay: 0s;
        }
      }
      .quotes-img {
        position: absolute;
        top: 55px;
        left: 49px;
      }

      &__auth {
        display: flex;
        align-items: center;
        gap: 0 30px;
        /* flex-wrap: wrap; */
        margin-top: 45px;
        overflow: hidden;
        img {
          min-height: 70px;
          min-width: 70px;
          height: 70px;
          width: 70px;
          border-radius: 50%;
          object-fit: cover;
          transform: translateY(50px);
          transition: 0.5s ease;
          transition-delay: 0s;
        }

        .groups {
          flex-grow: 1;
          transform: translateY(50px);
          transition: 0.5s ease;
          transition-delay: 0s;
          h4 {
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
          }

          p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .testimonials__navs {
      min-width: 80%;
      margin-bottom: 40px;
      margin-top: 60px;
      padding-left: 15px;
    }

    .col-sm-7 {
      min-width: 80%;
    }
  }

  @media (max-width: 767px) {
    .testimonials__navs {
      min-width: 100%;
      margin: 80px 0 40px 0;
      padding-left: 15px;

      h4 {
        margin-bottom: 40px;
      }
    }

    .col-sm-7 {
      min-width: 100%;
      margin: 0;
    }

    .testimonials__slides__single {
      border: 0;
      padding: 0;

      .quotes-img {
        top: -15px;
        left: -12px;
      }

      .groups {
        min-width: 100%;
        margin-top: 20px;
      }
    }
  }
  .swiper-slide:not(.swiper-slide-active) {
    opacity: 0 !important;
    transition: all 0.1s ease;
  }
  .swiper-slide-active {
    .testimonials__slides__single {
      &__desc {
        p {
          opacity: 1;
          transform: none;
        }
      }
      &__auth {
        img {
          transform: none;
        }
        .groups {
          transform: none;
        }
      }
    }
  }
`;
export default React.memo(Testimonial);
