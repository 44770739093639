import React, { useRef } from "react";
import { StyledNavigationIcon } from "./navigationIcon.style";
import PropTypes from "prop-types";

const NavigationIcon = ({
  navigationNext,
  navigationPrev,
  color,
  width,
  height,
  navigationNextClass,
  navigationPrevClass,
  onClickPrev,
  onClickNext,
  ClassName,
}) => {
  const lineRef1 = useRef(null);
  const lineRef2 = useRef(null);

  return (
    <StyledNavigationIcon className={`navigation_button ${ClassName}`}>
      <ul>
        <li
          className={`hover_left ${navigationPrevClass}`}
          id={navigationPrev ? navigationPrev : "service-prev"}
        >
          <svg
            className={"hover_left_svg"}
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Mask"
                  width="30"
                  height="30"
                  transform="translate(248 4141)"
                  fill="#e62e43"
                />
              </clipPath>
            </defs>
            <g
              id="Animation_-_Nav_Button"
              data-name="Animation - Nav Button"
              transform="translate(30 30) rotate(180)"
            >
              <g
                id="Mask_Group_492"
                data-name="Mask Group 492"
                transform="translate(-248 -4141)"
                clip-path="url(#clip-path)"
              >
                <rect
                  id="Hover"
                  width="30"
                  height="30"
                  transform="translate(248 4141)"
                  fill="#e62e43"
                />
                <rect
                  id="Box"
                  width="2"
                  height="30"
                  transform="translate(245 4141)"
                  fill="#191919"
                />

                <g id="Arrow" transform="translate(0 0.5)">
                  <line
                    ref={lineRef1}
                    id="Line_12481"
                    data-name="Line 12481"
                    x2="7"
                    y2="7"
                    transform="translate(259.5 4148.5)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    ref={lineRef2}
                    id="Line_12482"
                    data-name="Line 12482"
                    y1="7"
                    x2="7"
                    transform="translate(259.5 4155.5)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>

                <g id="Arrow_hover" opacity={0} transform="translate(0 0.5)">
                  <line
                    id="Line_12481"
                    data-name="Line 12481"
                    x2="7"
                    y2="4.5"
                    transform="translate(259.5 4151)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_12482"
                    data-name="Line 12482"
                    y1="4.5"
                    x2="7"
                    transform="translate(259.5 4155.5)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </g>
          </svg>
        </li>
        <li
          className={`hover_right ${navigationNextClass}`}
          id={navigationNext ? navigationNext : "service-next"}
        >
          <svg
            id="Animation_-_Nav_Button"
            data-name="Animation - Nav Button"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Mask"
                  width="30"
                  height="30"
                  transform="translate(248 4141)"
                  fill="#e62e43"
                />
              </clipPath>
            </defs>
            <g
              id="Mask_Group_492"
              data-name="Mask Group 492"
              transform="translate(-248 -4141)"
              clip-path="url(#clip-path)"
            >
              <rect
                id="Hover"
                width="30"
                height="30"
                transform="translate(248 4141)"
                fill="#e62e43"
              />
              <rect
                id="Box"
                width="2"
                height="30"
                transform="translate(245 4141)"
                fill="#191919"
              />
              <g id="Arrow" transform="translate(0 0.5)">
                <line
                  id="Line_12481"
                  data-name="Line 12481"
                  x2="7"
                  y2="7"
                  transform="translate(259.5 4148.5)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_12482"
                  data-name="Line 12482"
                  y1="7"
                  x2="7"
                  transform="translate(259.5 4155.5)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>

              <g id="Arrow_hover" opacity={0} transform="translate(0 0.5)">
                <line
                  id="Line_12481"
                  data-name="Line 12481"
                  x2="7"
                  y2="4.5"
                  transform="translate(259.5 4151)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <line
                  id="Line_12482"
                  data-name="Line 12482"
                  y1="4.5"
                  x2="7"
                  transform="translate(259.5 4155.5)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>
        </li>
      </ul>
    </StyledNavigationIcon>
  );
};

// Define prop types for the component
NavigationIcon.propTypes = {
  navigationNext: PropTypes.string,
  navigationPrev: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// Set default props for the component
NavigationIcon.defaultProps = {
  width: "30",
  height: "30",
};

export default React.memo(NavigationIcon);
