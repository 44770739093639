"use client";
import React from "react";
import styled from "styled-components";
import ThemeRow from "@/components/theme/row";
import { Col } from "react-bootstrap";
import GuidanceComponent from "@/components/common/GuidanceComponent";
import { Img } from "@/components/Img";
import useDeviceType from "@/components/hooks/useDeviceType";

const CTA = ({ data, ClassName }) => {
  const isMobile = useDeviceType();


  return (
    <>
      <StyledComponent className={`contact_for ${ClassName ? ClassName : ""}`}>
        <Img parallax src={data?.bg_img} alt={"CTA"} />

        <ThemeRow>
          <Col md={7}>
            <h4 className={"split-up"}>
              The future is bright, if it’s created on your terms.
            </h4>
          </Col>
          {isMobile ? null : (
            <Col md={{ span: 4, offset: 1 }}>
              <GuidanceComponent
                modalClass={`contact-modal ${ClassName ? ClassName : ""}`}
                data={data}
              />
            </Col>
          )}
        </ThemeRow>
      </StyledComponent>
      <>{isMobile ? <GuidanceComponent data={data} /> : null}</>
    </>
  );
};

const StyledComponent = styled.div`
  position: relative;
  padding: 320px 0 80px;
  @media (min-width: 1024px) {
    img {
      height: 110% !important;
      top: -50px !important;
      width: 100% !important;
    }
  }
  h4 {
    font-family: "Butler";
    font-size: 68px;
    letter-spacing: 0.5px;
    font-weight: 600;
    line-height: 72px;
    color: white;
    @media (max-width: 767px) {
      font-size: 52px;
      line-height: 60px;
    }
  }

  @media (min-width: 992px) {
    .guidance_components {
      position: relative;
      top: 150px;
      z-index: 99;
    }
  }

  @media (max-width: 992px) {
    .col-md-7,
    .col-md-4 {
      max-width: 100%;
      flex: 0 0 100%;
      margin: 0;
    }

    .guidance_components {
      margin-top: 40px;
    }
  }

  @media (max-width: 767px) {
    padding: 160px 0;
  }
`;
export default React.memo(CTA);
