"use client";
import React from "react";
import Banner from "@/components/home/Banner";
import StockBrokerage from "@/components/home/StockBrokerage";
import Benefits from "@/components/home/Benefits";
import Quotes from "@/components/home/Quotes";
import Testimonial from "@/components/home/Testimonial";
import ServiceList from "@/components/home/ServiceList";
import CTA from "@/components/common/CTA";
import Media from "@/components/home/Media";
import styled from "styled-components";
import { getApi } from "@/api";
import {
  FadeUp,
  ParallaxX,
  splitLeft,
  ParallaxXR,
} from "../animations/TextAnimations";

export default function AllHome({ getHomeData }) {
  // const apiValue = "home";
  // const getHomeData = await getApi(apiValue);

  const banner = getHomeData?.page_data?.banner;
  const latest = getHomeData?.page_data?.latest;
  const overview = getHomeData?.page_data?.overview;
  const benefit = getHomeData?.page_data?.benefit;
  const quote = getHomeData?.page_data?.quote;
  const testimonial = getHomeData?.page_data?.testimonial;
  const contact_form = getHomeData?.page_data?.contact_form;
  const media_center = getHomeData?.page_data?.media_center;
  const services = getHomeData?.services;

  // split text
  // useSplitTextAnimation(".split-up", [data]);
  // useSplitLeftAnimation('.split-left', [data])

  ParallaxX(getHomeData);
  ParallaxXR(getHomeData);
  FadeUp(getHomeData);
  splitLeft(getHomeData);

  return (
    <div className="home-page">
      <Banner latest={latest} data={banner} />
      <StockBrokerage data={overview} />
      <ServiceList data={services} />
      <Benefits data={benefit} />
      <Quotes data={quote} />
      <Testimonial data={testimonial} />
      <CTA ClassName={"for-home"} data={contact_form} />
      <Media data={media_center} />
    </div>
  );
}
