import { useEffect } from 'react';

const useAutoplay = (swiperRef, autoplayDelay = 5000) => {
    useEffect(() => {
        const swiper = swiperRef.current;
        const autoplayTimeout = setTimeout(() => {
            if (swiper && swiper.autoplay) {
                swiper.autoplay.start();
            }
        }, autoplayDelay);

        return () => {
            clearTimeout(autoplayTimeout);
        };
    }, [swiperRef, autoplayDelay]);
};

export default useAutoplay;
