"use client";
import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Img } from "@/components/Img";
import Title from "@/components/Title";
import TitleSerial from "@/components/TitleSerial";
import SectionBg from "@/public/images/static/benefit-bg.png";
import { hover, title } from "@/styles/globalStyleVars";
import ThemeRow from "@/components/theme/row";
import reactHtmlParser from "react-html-parser";

const Benefits = ({ data }) => {
  return (
    <StyledComponent className="benefits pt-200 pb-120">
      <Img objectFit={"contain"} src={data?.bg_img}  alt={data?.section_name}/>

      <ThemeRow>
        <Col sm={5} className={'d-flex align-items-center'}>
          {data?.section_name && (
            <>
              <TitleSerial line text={"III"} />
              <Title
                  background={"#191919"}
                  number={"III"}
                  text={data?.section_name}
              />
            </>

          )}
        </Col>

        {/*<Col sm={6}>*/}
        {/*  <TitleSerial text={"III"} />*/}
        {/*</Col>*/}

        <Col data-speed={"clamp(1.2)"} sm={{ span: 12 }}>
          {data?.title && (
            <h4 className={"split-up"}>{reactHtmlParser(data?.title)}</h4>
          )}
        </Col>

        <Col sm={{ span: 7, offset: 1 }} className={"benefits__items-row"}>
          <Row>
            {data?.list &&
              data?.list?.length > 0 &&
              data?.list?.map((e, index) => {
                return (
                  <Col
                    key={index}
                    sm={6}
                    data-speed={`${index % 2 === 1 ? "1.2" : "1.2"}`}
                  >
                    <h5 className={"split-up"}>0{index + 1}</h5>
                    <p className={"split-up"}>{e?.item}</p>
                  </Col>
                );
              })}
          </Row>
        </Col>
      </ThemeRow>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
    overflow: hidden;
    position: relative;
    background: white;

    .title {
      padding-left: 5px;
    }

    h4 {
        font-size: 120px;
        line-height: 120px;
        font-weight: 600;
        margin-top: 80px;
        font-family: Butler;

        span {
            display: block;
            //width: 63%;
            //margin-left: auto;
        }
    }

    .benefits__items-row {
        margin-top: 80px;

        .col-sm-6 {
            margin-bottom: 80px;

            &:nth-child(odd) {
                padding-right: 40px;
            }

            &:nth-child(even) {
                padding-left: 40px;
            }
        }

        h5 {
            font-size: 36px;
            line-height: 40px;
            color: ${hover};
            margin-bottom: 14px;
            font-family: ${title};
        }

        p {
            font-size: 20px;
            line-height: 28px;
        }
    }

    @media (max-width: 1150px) {
        h4 {
            font-size: 100px;
            line-height: 100px;
        }
    }

    @media (max-width: 991px) {
        h4 {
            span {
                display: initial;
            }
        }

        .benefits__items-row {
            min-width: 80%;
        }
    }

    @media (max-width: 768px) {
        .col-sm-5 {
            min-width: 100%;
        }

        .col-sm-11 {
            min-width: 100%;
            margin: 0;
        }
    }

    @media (max-width: 767px) {
        h4 {
            font-size: 52px;
            line-height: 60px;
            margin-top: 125px;
            margin-bottom: 40px;
        }

        .benefits__items-row {
            min-width: 100%;
            margin: 0;

            .col-sm-6 {
                min-width: 100%;
                padding: 0 15px !important;
                margin-bottom: 40px;
            }
        }
    }
`;
export default React.memo(Benefits);
