"use client";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import Title from "@/components/Title";
import ThemeContainer from "@/components/theme/container";
import ThemeRow from "@/components/theme/row";
import Slider, { Slide } from "@/components/ui/swiper";
import React, { useRef } from "react";
import Button from "@/components/Button";
import SingleMedia from "@/components/media/SingleMedia";
import useContainerOffset from "@/components/hooks/useContainerOffset";
import NavigationIcons from "@/components/ui/navigation-icons";

const Media = ({ data }) => {
  const SwiperRef = useRef();
  const offset = useContainerOffset(".footer .container");

  // Function to initialize swiper
  const handleSwiperInit = (swiper) => {
    return swiper;
  };

  const media_list = data?.feature_media;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <StyledComponent className="testimonials pt-200 pb-200" offset={offset}>
      <ThemeContainer colProps={{ md: 7 }}>
        <div data-speed={"clamp(1.1)"}>
          <Title
            text={"Media Center"}
            version
            number={"V"}
            background={"#FFFFFF"}
          />
        </div>
      </ThemeContainer>

      <ThemeRow>
        <Col
          sm={{ span: 12 }}
          className={"d-flex justify-content-between media-center-wrapper"}
        >
          <NavigationIcons
            ClassName={media_list?.length > 3 ? "" : "opacity"}
            navigationPrev={"media_prev"}
            navigationNext={"media_next"}
          />
          <Button src={"/ucb-hub"} text={"View All"} />
        </Col>
      </ThemeRow>
      <ThemeContainer
        fluid={true}
        containerClass={"media-slider-wrapper-content"}
        colProps={{ span: 12 }}
      >
        {media_list && media_list?.length > 0 && (
          <Slider
            ref={SwiperRef}
            navigationLeft={"#media_next"}
            navigationRight={"#media_prev"}
            speed={1000}
            slidesPerView={3}
            // slidesPerGroup={3}
            effect={false}
            loop={true}
            autoplay={false}
            spaceBetween={15}
            grabCursor={false}
            watchSlidesProgress={false}
            mousewheelControl={false}
            keyboardControl={false}
            onSwiperInit={handleSwiperInit}
            pagination={false}
            breakpoints={{
              1024: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
            }}
          >
            {media_list &&
              media_list?.length > 0 &&
              media_list?.map((e, index) => {
                var date = new Date(e.post_date_gmt);

                // Extract day, month, and year
                var day = date.getDate();
                var month = date.toLocaleString("default", { month: "long" });
                var year = date.getFullYear();

                return (
                  <Slide key={index}>
                    <SingleMedia
                      title={e?.post_title}
                      date={day}
                      year={year}
                      img={e?.thumbnail}
                      month={month}
                      link={`/ucb-hub/${e?.post_name}`}
                      type={e?.media_cat}
                      short_desc={e?.excerpt}
                    />
                  </Slide>
                );
              })}
          </Slider>
        )}
      </ThemeContainer>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  background-color: #f4f4f4;
  overflow: hidden;
  position: relative;
    .title {
        p{
            margin-right: 10px;
        }
        h2 {
            padding: 0 !important;

            &:after , &:before{
                display: none !important;
            }
        }
    }
  @media (max-width: 767px) {
    padding-bottom: 120px;
  }

  .media-slider-wrapper-content {
    /* padding-left: ${(props) => props.offset + "px" || "90px"}; */
    padding: 0;
  }

  .swiper {
    padding-left: ${(props) => props.offset + "px" || "90px"};
  }

  .title:after {
    opacity: 1;
    bottom: -65px;
  }

  .media-center-wrapper {
    margin-bottom: 40px;
    margin-top: 80px;
    @media (min-width: 1024px) {
      padding-left: 105px;
    }
  }

  @media (min-width: 768px) {
    .swiper-initialized {
      padding-right: 100px;
    }
  }

  .swiper-slide {
    &.swiper-slide-active {
      h2 {
        color: #fff;
      }
      .meta-font {
        color: #fff;
        transform: translateY(0px);
      }
      h4 {
        color: #fff;
        transform: translateY(0px);
      }
      p {
        opacity: 1;
        transform: translateY(20px);
      }
      .noise {
        height: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .media-slider-wrapper-content {
      padding: 0 15px;
    }
  }
`;
export default React.memo(Media);
