import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Img } from "@/components/Img";
import { Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Button from "@/components/Button";
import ThemeRow from "@/components/theme/row";

const ServicesItem = ({ title, shortDesc, link, ButtonLabel, imageSrc }) => {
  return (
    <StyledServicesSlider>
      <div className="slide-inner">
        <Img src={imageSrc ? imageSrc : "/images/dynamic/slider1.jpg"} alt={title}/>
        <ThemeRow>
          <Col md={4}>
            <div className="slide-inner__info">
              <h1 className={"swiper__title"}>
                {ReactHtmlParser(title ? title : "Individual Account")}
              </h1>
            </div>
          </Col>
          <Col md={{ offset: 2, span: "6" }} className={"slider-desc"}>
            <p className={"slider-text"}>{shortDesc}</p>
            <div className={"banner-button"}>
              <Button
                src={link ? link : "/services/slug"}
                text={ButtonLabel ? ButtonLabel : "Learn More"}
                color={"white"}
                margin={"40px 0 0 0"}
                iconBackground={"#FFFFFF"}
                lineColor={"#E62E43"}
                hoverColor={"#E62E43"}
                noAnim
              />
            </div>
          </Col>
        </ThemeRow>
      </div>
    </StyledServicesSlider>
  );
};

export const StyledServicesSlider = styled.div``;
ServicesItem.propTypes = {
  link: PropTypes.string.isRequired, // Function to call when this slide is clicked
  title: PropTypes.string.isRequired, // The title of this slide, which may contain HTML
  imageSrc: PropTypes.string.isRequired, // The title of this slide, which may contain HTML
  ButtonLabel: PropTypes.string.isRequired, // The title of this slide, which may contain HTML
  shortDesc: PropTypes.string.isRequired, // The title of this slide, which may contain HTML
};

export { ServicesItem };
