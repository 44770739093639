"use client";
import React from "react";
import styled from "styled-components";
import { Img } from "@/components/Img";
import { hover } from "@/styles/globalStyleVars";
import ThemeContainer from "@/components/theme/container";
import reactHtmlParser from "react-html-parser";

const Qoutes = ({ data }) => {
  return (
    <StyledComponent className="quotes pt-200 pb-200">
      {data?.bg_img && <Img src={data?.bg_img} alt={reactHtmlParser(data?.text)} />}

      <ThemeContainer colProps={{ md: 12 }}>
        {data?.text && (
          <h2 className={"fade-up"}>{reactHtmlParser(data?.text)}</h2>
        )}

        {data?.person && <p className={"fade-up"}>{data?.person}</p>}
      </ThemeContainer>
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  position: relative;

  .container {
    position: relative;
    z-index: 2;
  }

  p {
    margin-top: 60px;
    font-weight: bold;
    margin-left: 100px;
  }

  h2 {
    font-size: 68px;
    line-height: 72px;
    font-weight: 500;

    span {
      color: ${hover};
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 36px;
      line-height: 40px;
    }

    p {
      margin-top: 40px;
      margin-left: 15px;
    }
  }
`;
export default React.memo(Qoutes);
