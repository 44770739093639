"use client";
import styled from "styled-components";
import { Black, hover, text, title, White } from "@/styles/globalStyleVars";
import Link from "next/link";
import { Img } from "@/components/Img";

const ProjectBox = ({
  img,
  title,
  link,
  type,
  date,
  month,
  year,
  short_desc,
}) => {
  return (
    <StyledProjectBox>
      <div className="wrapper">
        <div className="blog-single">
          <div className="blog-single__inner">
            <Link href={link}  prefetch={true}/>
            <Img src={img} alt={title} />
            <div className="blog-single__inner__content">
              <div className="blog-single__inner__content__top">
                <h4>{title}</h4>
                <p>{short_desc}</p>
              </div>
              <div className="blog-single__inner__content__bottom">
                <div className="date">
                  <h2>{date}</h2>
                  <p className={"meta-font"}>
                    {month} {year}
                  </p>
                </div>
                <p className={"meta-font cat"}>{type}</p>
              </div>
            </div>
            <div className="noise" />
          </div>
        </div>
      </div>
    </StyledProjectBox>
  );
};

const StyledProjectBox = styled.div`
  .global-image {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000;
      opacity: 0.3;
      height: 100%;
      width: 100%;
    }
  }

  .blog-single {
    border: 1px solid #191919;

    &__inner {
      padding-top: calc(470 / 370 * 100%);
      position: relative;
      overflow: hidden;

      a {
        position: absolute;
        inset: 0;
        z-index: 3;
      }

      &__content {
        &__top {
          position: absolute;
          bottom: 40px;
          left: 40px;
          right: 40px;

          h4 {
            position: relative;
            z-index: 2;
            color: ${text};
            margin: 0;
            transition: all 0.45s ease-in-out;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            transform: translateY(115px);

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @media (min-width: 1536px) {
              transform: translateY(65px);
            }
            @media (max-width: 992px) {
              transform: translateY(0);
            }
          }

          p {
            //padding-top: 20px;
            //display: none;
            min-height: 100px;
            color: #fff;
            opacity: 0;
            transform: translateY(100px);
            transition: all 0.5s ease-in-out;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            @media (max-width: 992px) {
              display: none;
            }
          }

          @media (max-width: 767px) {
            bottom: 30px;
            left: 30px;
            right: 30px;
          }
        }

        &__bottom {
          position: absolute;
          margin: 0;
          top: 40px;
          left: 40px;
          right: 40px;
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          z-index: 2;

          h2 {
            color: ${text};
            font-size: 68px;
            line-height: 72px;
            font-family: ${title};
            transition: all 0.7s ease-in-out;
          }

          p {
            color: ${text};
            font-weight: 500;
            transition: all 0.7s ease-in-out;
          }
        }

        .cat {
          color: ${Black};
        }
      }

      .noise {
        position: absolute;
        inset: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background: #ffffff;
        transition: all 0.5s ease-in-out;

        &:after {
          content: "";
          background-color: #f4f4f4;
          width: 100%;
          height: 100%;
          position: absolute;
          inset: 0;
        }
      }

      &:hover {
        .blog-single__inner__content:after {
          height: 0;
        }

        .noise {
          height: 0;
        }

        .blog-single__inner__content__top {
          h4 {
            //opacity: 0;
            transform: translateY(0);
            color: ${White};
          }

          p {
            //display: block;
            opacity: 1;
            transform: translateY(20px);
            transition: transform 0.5s ease-in-out;
          }
        }

        .blog-single__inner__content__bottom {
          border-color: ${White};

          h2 {
            color: ${White};
          }

          p {
            color: ${White};

            span {
              color: ${White};
            }
          }
        }
      }
    }
  }
`;

export default ProjectBox;
