import PropTypes from 'prop-types';
import React from 'react';
import reactHtmlParser from 'react-html-parser';

const NavigationSlider = ({ activeIndex, index, onClick, title }) => {
    return (
        <div
            className={`slide-inner-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => onClick(index)} // Call onClick with the index when the div is clicked
        >
            {
                title &&
                <span>{reactHtmlParser(title)}</span>
            }

        </div>
    );
};

NavigationSlider.propTypes = {
    activeIndex: PropTypes.number.isRequired, // The currently active slide index
    index: PropTypes.number.isRequired, // The index of this slide
    onClick: PropTypes.func.isRequired, // Function to call when this slide is clicked
    title: PropTypes.string, // The title of this slide, which may contain HTML
};

export { NavigationSlider };
