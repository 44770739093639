import PropTypes from 'prop-types';
import React from 'react';
import Link from "next/link";
import reactHtmlParser from "react-html-parser";
import styled from "styled-components";
import {hover} from "@/styles/globalStyleVars";




const LatestNews = ({title, date, link, titleSecond, dateSecond, linkSecond}) => {

    return (
        <StyledLatestNewsFull>
            <ul>

                <li>
                    <Link  prefetch={true} href={link ? link : '/'}>
                        <p >{reactHtmlParser(title)}</p> | <strong>{date}</strong>
                    </Link>
                </li>
                <li>
                    <Link  prefetch={true} href={linkSecond ? linkSecond : '/'}>
                        <p >{reactHtmlParser(titleSecond)}</p> | <strong>{dateSecond}</strong>
                    </Link>
                </li>
            </ul>
        </StyledLatestNewsFull>
    );
};

export const StyledLatestNewsFull = styled.div`
    list-style-type: none;
    padding: 0;
    margin: 0;

    ul {
        display: flex;
        flex-direction: column;
        li {
            margin-bottom: 10px;
       

            &:last-child {
                margin-bottom: 0;
            }

            a {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                color: white;
                display: inline-flex;

                p {
                    margin-right: 5px;
                    font-size: 16px;
                    font-weight: 400 !important;
                    line-height: 20px;
                    color: white;
                }

                strong {
                    display: inline-block;
                    margin-left: 5px;
                    font-size: 16px;
                    font-weight: 400 !important;
                    line-height: 20px;
                    color: white;
                }
                position: relative;
                padding-bottom: 3px;
                &:after{
                    content: '';
                    height: 1px;
                    width: 0;
                    background: ${hover};
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transition: 0.7s all ease;
                }
                &:hover {
                    p, strong {
                        color: #E62E43;
                    }
                    &:after{
                        width: 100%;
                    }
                }
            }
   
        }
    }



    @media(max-width: 767px){
        ul{
            li{
                margin-bottom: 10px;
                a{
                    display: flex;
                    flex-wrap: wrap;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
`;
LatestNews.propTypes = {
    date: PropTypes.string.isRequired, // The currently active slide index
    link: PropTypes.string.isRequired, // Function to call when this slide is clicked
    title: PropTypes.string.isRequired, // The title of this slide, which may contain HTML
    dateSecond: PropTypes.string.isRequired, // The currently active slide index
    linkSecond: PropTypes.string.isRequired, // Function to call when this slide is clicked
    titleSecond: PropTypes.string.isRequired, // The title of this slide, which may contain HTML
};


export {LatestNews};
