"use client";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { hover } from "@/styles/globalStyleVars";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import Title from "@/components/Title";
import ThemeContainer from "@/components/theme/container";
import Slider, { Slide } from "@/components/ui/swiper";
import { ServicesItem } from "@/components/slider/slide/services-item";
import { NavigationSlider as NavigationItem } from "@/components/slider/slide/navigation-slider";
import useContainerOffset from "@/components/hooks/useContainerOffset";
import { useGSAP } from "@gsap/react";
import { usePathname } from "next/navigation";

const ServiceList = ({ data, margin }) => {
  gsap.registerPlugin(SplitText, ScrollTrigger);
  const swiperRef = useRef(null);
  const swiperRefTwo = useRef(null);
  const offset = useContainerOffset(".container") + 15;
  const pathName = usePathname();
  // navigation
  const prev = () => {
    document.querySelector(".home-banner .swiper-button-prev").click();
  };

  const next = () => {
    document.querySelector(".home-banner .swiper-button-next").click();
  };

  // handle slider progress
  const handleProgress = (swiper, event) => {
    var interleaveOffset = 0.5;
    for (let i = 0; i < swiper.slides.length; i++) {
      var slideProgress = swiper.slides[i].progress;
      var innerOffset = swiper.width * interleaveOffset;
      var innerTranslate = slideProgress * innerOffset;
      swiper.slides[i].querySelector(".global-image").style.transform =
        "translate3d(" + innerTranslate + "px, 0, 0)";
    }
  };

  // hand touch move not required this slider
  const handleTouchStart = (swiper) => {
    for (let i = 0; i < swiper.slides.length; i++) {
      swiper.slides[i].style.transition = "";
    }
  };

  // handle image transition on change
  const handleSetTransition = (swiper, speed) => {
    for (let i = 0; i < swiper.slides.length; i++) {
      swiper.slides[i].style.transition = `${speed}ms`;
      swiper.slides[i].querySelector(
        ".global-image"
      ).style.transition = `${speed}ms`;
    }
  };

  // slider-speed-control for mobile and laptop
  const [swiperSpeed, setSwiperSpeed] = useState("900");

  // Check if the device is a mobile
  useEffect(() => {
    const isMobileDevice = /Mobi/i.test(navigator.userAgent);

    // If it's a mobile device, set the speed to '1500'
    if (isMobileDevice) {
      setSwiperSpeed("900");
    }
  }, []);

  // autoplay off in mobile

  useEffect(() => {
    const swiper = swiperRef.current?.swiper;

    // Check if the user is on a mobile device
    const shouldDisableAutoplay = true;

    // Disable autoplay if on a mobile device
    if (shouldDisableAutoplay) {
      swiper?.autoplay.stop();
    } else {
      swiper?.autoplay.start();
    }
  }, [data]);

  // useEffect(() => {
  //   ScrollTrigger.refresh();
  //   if (window.innerWidth > 991) {
  //     ScrollTrigger.create({
  //       trigger: ".home-page .service-banner",
  //       pin: true,
  //       pinSpacing: false,
  //       start: "top -100",
  //       //   end: "+=100",
  //       markers: true,
  //     });
  //   }
  // }, [data]);

  useLayoutEffect(() => {
    if (window.innerWidth > 991) {
      const ctx = gsap.context(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".home-page .service-banner",
            start: "top top",
            pin: ".home-page .service-banner",
            // pinSpacing: false,
            // pinnedContainer: imagesContainer.current,
            // endTrigger: divRef.current,
            // end: "bottom bottom",
            // scrub: 1,
            // markers: true,
          },
        });
      });
      return () => ctx.revert();
    }
  }, [data]);

  // handle pagination
  var pagination_title = [
    "Financial freedom at the palm of your hands",
    "Investing in growth to open new big opportunities",
    "Financial freedom at the palm of your hands",
    "Investing in growth to open new big opportunities",
  ];
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<div class="' +
        className +
        '"><span>' +
        pagination_title[index] +
        "</span></div>"
      );
    },
  };
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = (event) => {
    const newActiveSlideIndex = event.realIndex;
    setActiveSlideIndex(newActiveSlideIndex);
    if (swiperRefTwo.current) {
      swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
    }
  };

  const handleButtonClickPagination = (index) => {
    if (swiperRef.current) {
      setActiveSlideIndex(index);
      swiperRef.current.swiper.slideToLoop(index);
    }
  };

  // useEffect(() => {
  //   document.fonts.ready.then(() => {
  //     applyButtonAnimation();
  //   });
  //   gsap.from(".banner-button", {
  //     duration: 0.8,
  //     translateY: 0,
  //     opacity: 1,
  //     rotationZ: "0",
  //     delay: 1.3,
  //     ease: "power4.out",
  //     scrollTrigger: {
  //       trigger: ".slide-inner",
  //       toggleActions: "restart none none reset",
  //     },
  //   });
  // }, [activeSlideIndex]);

  // useEffect(() => {
  //   document.fonts.ready.then(() => {
  //     applyTextAnimation();
  //   });
  //   gsap.from(".swiper__title", {
  //     duration: 0.8,
  //     translateY: 0,
  //     opacity: 0,
  //     delay: 1.3,
  //     ease: "power4.out",
  //     scrollTrigger: {
  //       trigger: ".slide-inner",
  //       toggleActions: "restart none none reset",
  //     },
  //   });
  // }, [activeSlideIndex]);

  // useEffect(() => {
  //   document.fonts.ready.then(() => {
  //     applyDescAnimation();
  //   });
  //   gsap.from(".slider-text", {
  //     duration: 0.8,
  //     translateY: 0,
  //     opacity: 0,
  //     delay: 1.3,
  //     ease: "power4.out",
  //     scrollTrigger: {
  //       trigger: ".slide-inner",
  //       toggleActions: "restart none none reset",
  //     },
  //   });
  // }, [activeSlideIndex]);

  const handleSwiperInit = (swiper) => {
    return swiper;
  };

  return (
    <StyledBanner margin={margin} offset={offset} className={"service-banner"}>
      <div className={"service-title"}>
        <ThemeContainer colProps={{ md: 12 }}>
          <Title
            text={"Our Services"}
            version line
            number={"II"}
            background={"#ffffff33"}
            color={"#FFFFFF"}
          />
        </ThemeContainer>
      </div>

      {data && data?.length > 0 && (
        <Slider
          ref={swiperRef}
          onSwiperInit={handleSwiperInit}
          spaceBetween={0}
          loop={true}
          speed={swiperSpeed}
          onSlideChange={handleSlideChange}
          onProgress={handleProgress}
          onSetTransition={handleSetTransition}
          grabCursor={false}
          watchSlidesProgress={true}
          mousewheelControl={true}
          keyboardControl={true}
          navigationLeft={"#banner-prev"}
          navigationRight={"#banner-next"}
          pagination={pagination}
        >
          {data &&
            data?.length > 0 &&
            data?.map((e, index) => {
              return (
                <Slide key={index}>
                  <ServicesItem
                    ButtonLabel={"Learn More"}
                    link={`/services/${e?.post_name}`}
                    shortDesc={e?.post_excerpt}
                    imageSrc={e?.service_bg}
                    title={e?.post_title}
                  />
                </Slide>
              );
            })}
        </Slider>
      )}

      <div className={"service-navigation"}>
        <ThemeContainer colProps={{ md: 12 }}>
          <div className="swipper-navigation-slider-custom">
            {data && data?.length > 0 && (
              <Slider
                loop={false}
                autoplay={false}
                grabCursor={false}
                watchSlidesProgress={false}
                mousewheelControl={false}
                keyboardControl={false}
                ref={swiperRefTwo}
                speed={swiperSpeed}
                slidesPerView={4}
                spaceBetween={0}
                onSwiperInit={handleSwiperInit}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                }}
                modules={[Autoplay, Pagination, Navigation]}
                parentclass={"navigation_slider_services"}
              >
                {data &&
                  data?.length > 0 &&
                  data?.map((e, index) => {
                    return (
                      <Slide key={index}>
                        <NavigationItem
                          title={e?.post_title}
                          activeIndex={activeSlideIndex}
                          index={index}
                          onClick={handleButtonClickPagination}
                        />
                      </Slide>
                    );
                  })}
              </Slider>
            )}
          </div>
        </ThemeContainer>
      </div>
    </StyledBanner>
  );
};

const StyledBanner = styled.section`
    height: 100vh;
    overflow: hidden;
    position: relative;
    z-index: 9;
    margin: ${(p) => (p.margin ? p.margin : "0")};

    /* @media (min-width: 1536px) {
      height: 100vh;
    } */

    .title {
        p {
            margin-right: 10px;
        }

        h2 {
            padding: 0 !important;

            &:after, &:before {
                display: none !important;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination,
    .mobile {
        display: none;
    }

    .swiper-slide {
        overflow: hidden;
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-button-disabled {
        opacity: 0.5 !important;
    }

    .navigation_slider_services {
        width: 100%;
    }

    .global-image {
        transition: clipPath 0.8s cubic-bezier(0.29, 0.73, 0.45, 1),
        border-color 3.6s linear,
        -webkit-clipPath 0.8s cubic-bezier(0.29, 0.73, 0.45, 1);
        will-change: clipPath;
        overflow: hidden;
        -webkit-clippath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clippath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .swiper-slide-active,
    .swiper-slide-visible {
        .global-image {
            clippath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        .slide-inner {
            img {
                height: 100vh;
                @media (min-width: 1536px) {
                    height: 100vh;
                }

            }
        }
    }

    //custom navigation

    .swipper-navigation-slider-custom {
        display: flex;
        position: absolute;
        left: 0px;
        right: 0px;
        min-height: 75px;
        text-align: left;
        margin: 0px auto;
        padding-left: 15px;
        padding-right: 15px;
        -webkit-box-pack: justify;
        justify-content: space-between;
        gap: 15px;
        flex-direction: row;
        width: 100%;

        .swiper {
            width: 100% !important;
            margin: unset !important;
        }

        .slide-inner-item {
            width: 94%;
            position: relative;
            background: transparent !important;
            opacity: 1 !important;
            padding: 20px 40px 0 0;
            cursor: pointer;

            span {
                font-size: 16px;
                font-weight: 700;
                color: #ffffff;
                line-height: 20px;
                opacity: 1 !important;
                transition: 0.7s all ease;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 4px;
                background: white;
                width: 0;
                opacity: 0;
                z-index: 100;
                transition: 0.7s ease-in-out;
            }

            &:hover {
                &:after {
                    width: 100%;
                    opacity: 1;
                    background: #ffffff;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }

            &:before {
                content: "";
                background: transparent !important;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 99;
                border-top: 1px solid #c9c9c9;
                width: 100%;
            }

            &.active {
                &:after {
                    width: 100%;
                    opacity: 1;
                    background: white;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }

        .last-child {
            &.active {
                &:after {
                    width: 100%;
                    opacity: 1;
                    background: #fff;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }

            &:hover {
                &:after {
                    width: 100%;
                    opacity: 1;
                    background: #c9c9c9;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .service-navigation {
        position: relative;
        bottom: 120px;
        @media (max-width: 767px) {
            bottom: 65px;
        }
    }

    .slide-inner {
        position: relative;
        height: 100vh;
        @media (min-width: 1536px) {
            height: 100vh;
        }

        .container {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(50% - 15vh);
            //transform: translateY(-50%);
            z-index: 2;
            overflow: hidden;
        }

        .global-image {
            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 1;
                background: linear-gradient(
                        to right,
                        rgba(0, 0, 0, 0.4),
                        rgba(0, 0, 0, 0)
                );
            }
        }

        &__info {
            position: relative;
            overflow: hidden;
            display: flex;

            h1 {
                line-height: 72px;
                font-size: 68px;
                color: white;
                font-weight: 600;
                font-family: Butler;
                transform: translateY(100%);
                opacity: 0;
                transition: 0.6s ease;
                transition-delay: 0.8s;
                @media (max-width: 768px) {
                    font-size: 36px;
                    line-height: 40px;
                    margin-bottom: 40px;
                }
            }

            .banner-button {
                opacity: 0;
                overflow: hidden;

                .dc-btn {
                    position: relative;
                    overflow: hidden;
                }
            }

            //transform: translateX(50%);
            //transition: all 2s cubic-bezier(.25, .74, .22, .99);
            //transition-delay: 1.2s;
            //opacity: 0;
            @media (min-width: 1024px) {
                //width: 70%;
            }

            h1 {
                .line {
                    overflow: hidden;
                }
            }

            //.dc-btn {
            //  position: relative;
            //  //transform: translateY(400px);
            //  transition-delay: 1.2s;
            //  transform: translateX(50%);
            //
            //  transition: 2.4s all cubic-bezier(.25, .74, .22, .99);
            //  opacity: 0;
            //
            //}
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .slide-inner__info {
            margin-right: 50px;

            h1 {
                font-size: 60px;
                line-height: 68px;
            }
        }
    }

    //navigation

    .navigation {
        position: absolute;
        right: ${(props) => (props.offset ? props.offset : "90")}px;
        top: calc(50% - 10vh);
        display: inline-block;
        z-index: 1;

        ul {
            display: flex;
            flex-direction: column;

            li {
                cursor: pointer;

                &:first-child {
                    margin-bottom: 20px;
                    margin-right: 0px;
                }

                svg {
                    path,
                    #Rectangle_5661 {
                        //transition: stroke-dasharray 0.3s ease-in-out;
                        transition: all 1s cubic-bezier(0.25, 0.74, 0.22, 0.99);
                    }
                }

                &:hover {
                    svg {
                        #Rectangle_5661 {
                            opacity: 1;
                            stroke-dasharray: 140;
                        }

                        path {
                            stroke: ${hover};
                        }
                    }
                }
            }
        }
    }

    //responsive
    @media (min-width: 1550px) {
        .slide-inner {
            &__info {
                position: relative;
                overflow: hidden;

                h2 {
                    font-size: 80px;
                    line-height: 100px;
                }

                //.dc-btn {
                //  a {
                //    font-size: 22px;
                //    line-height: 33px;
                //  }
                //}
            }
        }

        .feature-box-container {
            bottom: -150px;
        }

        .feature-box-container {
            .single-item-wrapper {
                min-height: 300px;

                .content {
                    min-height: 250px;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .swipper-navigation-slider-custom {
            bottom: 250px;
        }

        .slide-inner {
            .col-md-4 {
                max-width: 100%;
                flex: 0 0 100%;
            }

            .col-md-6.offset-md-2 {
                max-width: 100%;
                flex: 0 0 100%;
                margin: 0;
            }
        }
    }

    @media (max-width: 992px) and (min-width: 768px) {
        height: 100vh;
        .service-navigation {
            bottom: 0;
        }

        .swipper-navigation-slider-custom {
            bottom: 60px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }

        .slide-inner {
            height: 100vh;

            .container {
                top: 45%;
            }
        }

        .feature-box-container {
            display: none;
            position: relative;
            left: 0px;
            right: 0px;
            bottom: 200px;
            z-index: 999;
            width: calc(100% - 30px);
            margin: 0px auto;

            .single-item-wrapper a .content {
                align-items: flex-start;
            }

            .feature-box-container_item:last-child {
                order: 1;
                flex: 0 0 100%;
                max-width: 100%;
            }

            .feature-box-container_item:first-child {
                order: 2;
                flex: 0 0 100%;
                max-width: 100%;

                .single-item-wrapper {
                    border-right: none;
                    border-bottom: 1px solid rgba(198, 198, 198, 0.5);
                }
            }

            .feature-box-container_item:nth-of-type(2) {
                order: 3;
                flex: 0 0 50%;
                max-width: 50%;
            }

            .feature-box-container_item:nth-of-type(3) {
                order: 4;
                flex: 0 0 50%;
                max-width: 50%;

                .single-item-wrapper {
                    border-right: none;
                }
            }
        }
    }
    @media (max-width: 767px) {
        //height: 100vh;
        height: auto;

        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }

        .slide-inner {
            height: 110vh;

            @media (max-width: 550px) {
                height: 150svh;
            }

          @media (max-width: 380px) {
            height: 160svh !important;
          }

            img {
                height: 100% !important;
            }

            .container {
                top: 350px;
                transform: unset;
                //bottom: 220px;
            }

            &__info {
                position: relative;
                overflow: hidden;

                h2 {
                    font-size: 40px;
                    line-height: 48px;
                }

                .dc-btn {
                    margin-top: 30px;
                }
            }
        }

        .swipper-navigation-slider-custom {
            padding-left: 15px !important;
            padding-right: 15px !important;
            bottom: 0px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }

        .scrollTo {
            left: 15px;
            right: unset;
            display: inline-block;
            bottom: 20px;
            transform: translateX(0);
        }

        .navigation {
            display: none;
        }

        .feature-box-container {
            display: none;
            position: relative;
            bottom: 150px;

            .feature-box-container_item {
                &:first-child {
                    order: 2;
                }

                &:nth-of-type(2) {
                    order: 3;
                }

                &:nth-of-type(3) {
                    order: 4;
                }

                &:last-child {
                    order: 1;

                    .content {
                    }
                }
            }

            .single-item-wrapper {
                border-right: none;
                border-bottom: 1px solid rgba(198, 198, 198, 0.5);

                .content {
                    align-items: flex-start !important;
                }

                &.last {
                    border: none;

                    .content {
                        align-items: center !important;
                    }
                }
            }

            .container {
                padding: 0;
            }
        }
    }
    @media (max-width: 375px) {
        .slide-inner{
          height: 150svh;
        }
    }

    .service-title {
        top: 120px;
        position: absolute;
        width: 100%;

        h2 {
            &:before {
                width: 96%;
            }
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .slider-desc {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        p {
            color: white;
            font-size: 24px;
            line-height: 32px;
            transform: translateY(100%);
            opacity: 0;
            transition: 0.6s ease;
            transition-delay: 0.8s;
        }

        .banner-button {
            transform: translateY(100%);
            /* opacity: 0; */
            transition: 0.6s ease;
            transition-delay: 0.8s;
        }
    }

    .swiper-slide-active {
        .slider-desc {
            p,
            .banner-button {
                opacity: 1;
                transform: none;
            }
        }

        .slide-inner__info h1 {
            opacity: 1;
            transform: none;
        }
    }
`;

export default React.memo(ServiceList);
