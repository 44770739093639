"use client";
import React, { useEffect, useRef, useState } from "react";
import useAutoplay from "@/components/hooks/useAutoplay";
import Slider, { Slide } from "@/components/ui/swiper";
import { SlideOne as SlideItem } from "@/components/slider/slide/slide-one";
import { NavigationSlider as NavigationItem } from "@/components/slider/slide/navigation-slider";
import styled from "styled-components";
import ThemeRow from "@/components/theme/row";
import { Col } from "react-bootstrap";
import { LatestNews } from "@/components/slider/slide/latest-news";
import { gsap } from "gsap";
import { usePathname } from "next/navigation";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, EffectFade } from "swiper";

const SliderMain = ({ containerGap, data, settings, className, latest }) => {
  // Refs for main home-slider and navigation home-slider
  const mainSliderRef = useRef(null);
  const navigationSliderRef = useRef(null);
  const latestSliderRef = useRef(null);

  var pagination_title = [""]; // Array for pagination titles
  const [activeSlideIndex, setActiveSlideIndex] = useState(0); // State for active slide index
  useAutoplay(mainSliderRef); // Hook for autoplay

  // Function to handle progress of slides
  const handleProgress = (swiper, event) => {
    const interleaveOffset = 0.5;
    const { slides } = swiper;

    slides.forEach((slide) => {
      const slideProgress = slide.progress;
      const innerOffset = swiper.width * interleaveOffset;
      const innerTranslate = slideProgress * innerOffset;
      const globalImage = slide.querySelector(".global-image");

      if (globalImage) {
        globalImage.style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
      }
    });
  };

  // Function to set transition of slides
  const handleSetTransition = (swiper, speed) => {
    swiper.slides.forEach((slide) => {
      slide.style.transition = `${speed}ms`;
      const image = slide.querySelector(".global-image");
      if (image) {
        image.style.transition = `${speed}ms`;
      }
    });
  };

  // Function to handle slide change event
  const handleSlideChange = (event) => {
    const newActiveSlideIndex = event.realIndex;
    setActiveSlideIndex(newActiveSlideIndex);
    if (navigationSliderRef?.current) {
      navigationSliderRef?.current.swiper.slideTo(newActiveSlideIndex);
    }
  };

  // Function to handle pagination button click
  const handleButtonClickPagination = (index) => {
    if (mainSliderRef?.current) {
      setActiveSlideIndex(index);
      mainSliderRef?.current.swiper.slideToLoop(index);
    }
  };

  // Function to initialize swiper
  const handleSwiperInit = (swiper) => {
    return swiper;
  };

  // Pagination configuration
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<div className="' +
        className +
        '"><span>' +
        pagination_title[index] +
        "</span></div>"
      );
    },
  };

  const pathName = usePathname();

  useEffect(() => {
    gsap.to(".custom-latest-news-wrapper", {
      opacity: 1,
      delay: 1,
      duration: 0.5,
    });
  }, [pathName]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  useEffect(() => {
    // Set initial state
    gsap.set(".home-banner", { opacity: 0 });

    gsap.to(".home-banner", {
      opacity: 1,
      ease: "easeInOut",
      duration: 1,
      delay: 1,
    });
  }, [pathName]);

  const [offset, setOffset] = useState(null);

  useEffect(() => {
    const theOffset = () => {
      setOffset(document.querySelector(".footer .container").offsetLeft);
    };
    window.addEventListener("resize", theOffset);
    theOffset();
  }, []);


  // demo length
  return (
    <StyledInteractiveBanner
      offset={offset}
      containerGap={containerGap}
      className={"home-banner"}
    >
      {data?.list && data?.list?.length > 0 && (
        <Slider
          ref={mainSliderRef}
          settings={settings}
          className={className}
          onSwiperInit={handleSwiperInit}
          spaceBetween={0}
          loop={true}
          speed={1000}
          onSlideChange={handleSlideChange}
          onProgress={handleProgress}
          onSetTransition={handleSetTransition}
          grabCursor={false}
          watchSlidesProgress={true}
          mousewheelControl={true}
          keyboardControl={true}
          pagination={pagination}
          navigationLeft={".swiper-button-next"}
          navigationRight={".swiper-button-prev"}
        >
          {data?.list &&
            data?.list?.length > 0 &&
            data?.list?.map((e, index) => {
              if (e?.video) {
                return (
                  <Slide key={index}>
                    <SlideItem
                      topTitle={e?.subtitle}
                      title={e?.title}
                      video
                      bannerLarge={
                        e?.video
                          ? e?.video
                          : "/videos/static/sample_video_2.mp4"
                      }
                      bannerSmall={
                        e?.m_video
                          ? e?.m_video
                          : e?.video
                          ? e?.video
                          : "/videos/static/sample_video_2.mp4"
                      }
                      buttonLabel={e?.button_label}
                      buttonLink={e?.button_url}
                      buttonTarget={"self"}
                      description={e?.desc ? e?.desc : ""}
                    />
                  </Slide>
                );
              } else {
                return (
                  <Slide key={index}>
                    <SlideItem
                      topTitle={e?.subtitle}
                      title={e?.title}
                      bannerLarge={
                        e?.image ? e?.image : "/images/static/blur_.jpg"
                      }
                      bannerSmall={
                        e?.m_image
                          ? e?.m_image
                          : e?.image
                          ? e?.image
                          : "/images/static/blur_.jpg"
                      }
                      buttonLabel={e?.button_label}
                      buttonLink={e?.button_url}
                      buttonTarget={"self"}
                      description={e?.desc ? e?.desc : ""}
                    />
                  </Slide>
                );
              }
            })}

          <div className="swiper-button-prev" />
          <div className="swiper-button-next" />
        </Slider>
      )}

      <div className={"custom-navigation-home-slider-wrapper"}>
        <ThemeRow>
          <Col md={12}>
            {data?.list && data?.list?.length > 0 && (
              <Slider
                ref={navigationSliderRef}
                speed={100}
                slidesPerView={4}
                loop={false}
                autoplay={false}
                spaceBetween={0}
                grabCursor={false}
                watchSlidesProgress={false}
                mousewheelControl={false}
                keyboardControl={false}
                settings={{
                  767: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                }}
              >
                {data?.list &&
                  data?.list?.length > 0 &&
                  data?.list?.map((e, index) => {
                    return (
                      <Slide key={index}>
                        <NavigationItem
                          activeIndex={activeSlideIndex}
                          index={index}
                          onClick={handleButtonClickPagination}
                        />
                      </Slide>
                    );
                  })}
              </Slider>
            )}
          </Col>
        </ThemeRow>
      </div>

      {
          data?.latest_blog &&
          <div className="custom-latest-news-wrapper">
            <ThemeRow>
              <Col md={2}>
                <p className={"latest-news"}>Lastest Insights</p>
              </Col>
              <Col md={8}>
                <div className="slider-news">
                  <div className={"custom-latest-news"}>
                    <LatestNews
                        date={formatDate(latest?.list?.[0]?.post_date_gmt)}
                        linkSecond={`/ucb-hub/${latest?.list?.[1]?.post_name}`}
                        link={`/ucb-hub/${latest?.list?.[0]?.post_name}`}
                        titleSecond={latest?.list?.[1]?.post_title}
                        title={latest?.list?.[0]?.post_title}
                        dateSecond={formatDate(latest?.list?.[1]?.post_date_gmt)}
                    />
                  </div>
                </div>
              </Col>
            </ThemeRow>
          </div>
      }

    </StyledInteractiveBanner>
  );
};

export const StyledInteractiveBanner = styled.section`
    height: 100vh;
    position: relative;
    opacity: 1 !important;

    /** 
        * all style for swiper slider 
      **/

    .swiper-button-prev,
    .swiper-button-next {
        width: ${(p) => p.offset}px;
        height: 100%;
        top: 0;
        margin: 0;
        transition: 0.7s all ease;
        display: block !important;
        z-index: 8;

        &::after {
            display: none;
        }
    }

    .swiper-button-prev {
        left: 0;
        cursor: url("/images/static/slider-left.svg") 32 32, auto;
    }

    .swiper-button-next {
        cursor: url("/images/static/slider-right.svg") 32 32, auto;
    }

    .slider-inner_right {
        .setImageOne {
            .image-size {
                height: 130px !important;
                margin-bottom: 20px;
            }

            &:last-child {
                .image-size {
                    margin-bottom: 0;
                }
            }
        }
    }

    .custom-latest-news-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
        z-index: 9999;
        opacity: 0;

        @media (min-width: 1025px) {
            .container {
                padding-left: 115px;
            }
        }

        p {
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            color: white;
        }

        @media (max-width: 992px) {
            .col-md-8 {
                max-width: calc(100% - 16.666667%);
                flex: 0 0 calc(100% - 16.666667%);
            }
        }

        @media (max-width: 767px) {
            .col-md-8 {
                max-width: 100%;
                flex: 100%;
            }

            .latest-news {
                margin-bottom: 20px;
            }

            ul {
                li {
                    margin-bottom: 10px;

                    a {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .tagDataSet {
        margin-top: 10px;

        a {
            color: black;
            margin-right: 10px;
            background: white;
            border-radius: 5px;
            padding: 5px 8px;
        }
    }

    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination,
    .mobile {
        display: none;
    }

    .swiper-slide {
        overflow: hidden;
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-button-disabled {
        opacity: 0.5 !important;
    }

    .top-title-wrapper {
        position: relative;
        overflow: hidden;
        min-height: 20px;

        p {
            position: relative;
            margin: 0 0 40px 0 !important;
            transform: translateX(50px);
            transition: 0.6s all ease;
            /* transition-delay: 0.5s; */
            opacity: 0;
        }
    }

    .title-wrapper-middle {
        position: relative;

        display: flex;
        overflow: hidden !important;
        margin-bottom: 30px;
        @media (min-width: 769px) {
            width: 70%;
        }

        h2 {
            position: relative;
            line-height: 78px;
            transition: 0.6s all ease;
            transition-delay: 0s;
            margin: 0 !important;
            opacity: 0;
            transform: translateY(40px);
        }
    }

    .middle-description {
        position: relative;
        overflow: hidden;

        p {
            position: relative;
            margin: 0 !important;
            transform: translateY(100px);
            transition: 0.7s all ease;
            transition-delay: 0.5s;
        }
    }

    .swiper-slide-active,
    .swiper-slide-visible {
        .global-image {
            clippath: black;
        }

        .title-wrapper-middle {
            position: relative;
            overflow: visible;

            h2 {
                position: relative;
                opacity: 1;
                transition-delay: 0.9s;
                transform: none;
            }
        }

        .middle-description {
            p {
                position: relative;
                margin: 0 !important;
                transform: translateY(0);
                transition-delay: 1s;
            }
        }

        .top-title-wrapper {
            position: relative;

            p {
                opacity: 1;
                transform: translateX(0);
            }
        }

        .slide-inner {
            video {
                position: absolute;
                inset: 0;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }

            .dc-btn {
                z-index: 9;
            }

            img {
                height: 100vh !important;
            }

            &__info {
                //transition-delay: 0.5s;
                //opacity: 1;
                //transform: translateX(0px) !important;

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    color: white;
                    opacity: 1;
                }

                .dc-btn {
                    transition-delay: 1.2s;
                    opacity: 1 !important;
                    transform: none !important;

                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .custom-navigation-home-slider-wrapper {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
        min-height: 75px;
        text-align: left;
        margin: 0 auto;
            //padding-left: ${(props) =>
                props.containerGap ? props.containerGap + 15 : "90"}px;
            //padding-right: ${(props) =>
                props.containerGap ? props.containerGap + 15 : "90"}px;
        justify-content: space-between;
        gap: 15px;
        flex-direction: row;
        z-index: 99;

        .swiper-wrapper {
            width: 100%;
            height: 3px;
            background-color: #fff;

            .swiper-slide {
                flex-grow: 1;
            }
        }

        @media (min-width: 1025px) {
            .container {
                padding-left: 115px;
            }
        }

        .swiper {
            opacity: 0;

            &.swiper-initialized {
                opacity: 1 !important;
            }
        }

        .container {
            margin-top: 0;
            margin-bottom: 0;
        }

        @media screen and (min-width: 1024px) {
            .slide-inner-item {
                min-width: 250px;
            }

            .swiper-backface-hidden .swiper-slide {
                min-width: 250px;
            }
        }

        .slide-inner-item {
            width: 100%;
            position: relative;
            background: transparent !important;
            opacity: 1 !important;
            padding: 20px 0 0;
            cursor: pointer;

            @media screen and (min-width: 1536px) {
                padding-right: 15px;
            }

            span {
                font-size: 20px;
                font-weight: 500;
                color: white;
                line-height: 28px;
                opacity: 1 !important;
                transition: 0.7s all ease;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 4px;
                background: #e62e43;
                width: 0;
                transition: 0.7s all ease;
                opacity: 0;
                z-index: 999;
            }

            &:hover {
                &:after {
                    width: 100%;
                    opacity: 1;
                }
            }

            &:before {
                content: "";
                background: #ffffff !important;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 4px;
                top: 0;
                z-index: 99;
            }

            &.active {
                &:after {
                    width: 100%;
                    opacity: 1;
                }
            }
        }

        @media (max-width: 767px) {
            bottom: 100px;
        }

        @media (max-width: 445px) {
            bottom: 80px;
        }
    }

    .slide-inner {
        position: relative;
        height: 100vh;

        video {
            position: absolute;
            inset: 0;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        .container {
            position: absolute;
            left: 0;
            right: 0;
            /* top: 50%; */
            /* transform: translateY(-50%); */
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .global-image {
            will-change: clipPath;
            overflow: hidden;
            -webkit-clippath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clippath: polygon(0 0, 100% 0, 100% 100%, 0 100%);

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 1;
                background: linear-gradient(
                        to right,
                        rgba(0, 0, 0, 0.4),
                        rgba(0, 0, 0, 0)
                );
            }
        }

        &__info {
            position: relative;
            //overflow: hidden;
            //transform: translateX(50%);
            transition: 0.7s all ease;
            //transition-delay: 1.2s;
            //opacity: 0;
            padding-bottom: 5px;

            .container {
                overflow: visible;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                color: white;
                transition: 0.7s all ease;
                transition-delay: 1.2s;
                //opacity: 0;
                @media (min-width: 1535px) {
                    width: 30% !important;
                }
                @media (min-width: 1024px) {
                    width: 60%;
                }
            }

            @media screen and (min-width: 1024px) {
                width: 100%;
            }

            .dc-btn {
                position: relative;
                transition-delay: 0s;
                //transform: translateX(50%);
                transition: 0.6s all ease;
                opacity: 0;
                transform: translateY(40px);
            }

            .toptitle {
                color: #ffffff;
                font-size: 24px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 10px;
                margin-bottom: 40px;

                @media (min-width: 550px) {
                    white-space: nowrap;
                }
            }

            .title {
                transition: 0.7s all ease;
                transition-delay: 1.5s;
                transform: none !important;

                &:after {
                    display: none;
                }
            }

            h1,
            h2 {
                color: #ffffff;
                font-size: 68px;
                line-height: 82px;
                font-weight: 600;
                letter-spacing: 0.5px;
                margin-bottom: 20px;
                text-transform: none;

                &:after {
                    display: none;
                }
            }

            .side-wrapper {
                padding-left: 100px;
                position: relative;

                &:before {
                    position: absolute;
                    left: -${(p) => p.offset + 25}px;
                    height: 1px;
                    background: white;
                    content: "";
                    top: 33px;
                    width: ${(p) => p.offset + 70}px;
                    transition: 0.7s all ease;
                    transition-delay: 0.7s;
                }
            }
        }
    }

    .navigation {
        position: absolute;
        right: ${(props) => (props.containerGap ? props.containerGap : "90")}px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        z-index: 1;

        ul {
            display: flex;
            flex-direction: column-reverse;

            li {
                cursor: pointer;

                &:first-child {
                    margin-right: 0;
                }

                &:last-child {
                    margin-bottom: 20px;
                }

                svg {
                    path,
                    #Rectangle_5661 {
                        //transition: strokeDasharray 0.3s ease-in-out;
                        transition: 0.7s all ease;
                    }
                }

                &:hover {
                    svg {
                        #Rectangle_5661 {
                            opacity: 1;
                            stroke-dasharray: 140;
                        }

                        path {
                            stroke: #e62e43;
                        }
                    }
                }
            }
        }
    }

    .nav-left {
        position: absolute;
        height: 100%;
        width: 50%;
        left: 0;
        z-index: 9;
        cursor: url("/images/static/slider-left.svg") 32 32, auto;
    }

    .nav-right {
        position: absolute;
        height: 100%;
        width: 50%;
        right: 0;

        z-index: 9;
        cursor: url("/images/static/slider-right.svg") 32 32, auto;
    }

    .swiper-wrapper {
        /* height: 3px; */
    }

    @media screen and (min-width: 1536px) {
        .slide-inner {
            &__info {
                position: relative;
                //overflow: hidden;

                h2 {
                    font-size: 80px;
                    line-height: 100px;
                }

                .dc-btn {
                    a {
                        font-size: 22px;
                        line-height: 33px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 992px) {
        .custom-navigation-slider-wrapper {
            bottom: 250px;
        }
    }
    @media (max-width: 992px) and (min-width: 768px) {
        height: 100vh;
        .custom-navigation-slider-wrapper {
            bottom: 60px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }

        .slide-inner {
            height: 100svh;

            /* .container {
              top: 45%;
            } */
        }

        .navigation {
            position: absolute;
            right: unset;
            top: unset;
            transform: unset;
            display: inline-block;
            z-index: 1;
            left: ${(props) => (props.containerGap ? props.containerGap : "90")}px;
            bottom: 180px;

            ul {
                flex-direction: row;

                li {
                    &:last-child {
                        margin-bottom: 0;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        height: 100vh;

        .mobile {
            display: block;
        }

        .side-wrapper {
            padding-left: 0 !important;
        }

        .title {
            padding: 0;

            &:before {
                display: none;
            }
        }

        .slide-inner {
            height: 100vh;

            .container {
                top: unset;
                transform: unset;
                /* bottom: 40%; */
            }

            &__info {
                position: relative;
                overflow: hidden;

                .toptitle {
                    margin-bottom: 20px;
                }

                h2,
                h1 {
                    font-size: 52px;
                    line-height: 60px;
                    font-weight: 600;
                }

                .dc-btn {
                    margin-top: 30px;
                }
            }
        }

        .custom-navigation-slider-wrapper {
            padding-left: 15px !important;
            padding-right: 15px !important;
            bottom: 30px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }

        .scrollTo {
            left: 15px;
            right: unset;
            display: inline-block;
            bottom: 20px;
            transform: translateX(0);
        }

        .navigation {
            display: none;
        }
    }
    @media (max-width: 1300px) and (min-width: 1024px) {
        .slide-inner__info h2, .slide-inner__info h1 {
            font-size: 50px;
            line-height: 65px;
        }
    }
    @media (max-width: 991px) {
        .slide-inner__info .side-wrapper:before {
            display: none;
        }

        .slide-inner .container {
            height: 70%;
        }
    }

    @media (max-width: 767px) {
      .slide-inner .container{
        height: 90%;
      }
    
    }
`;

export default React.memo(SliderMain);
